/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import InvoiceStatus from 'utils/styles/InvoiceStatus.styles';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { setPage, storeInvoicesInfo } from 'store/invoices/invoicesSlice';
import { statusType } from 'utils/constants/invoiceStatus';
import { ADMIN, SALES_REP, SUPER_ADMIN } from 'utils/constants/roles';
import PayInvoicePopup from 'organisms/PayInvoicePopup/PayInvoicePopup';
import { setInvoice } from 'store/payInvoice/payInvoiceSlice';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { formatDate } from 'utils/helpers/dates';
import { snakeCase } from 'lodash-es';
import { ActionContainerStyled, PayButtonStyled } from './InvoicesTable.styles';
import { handleDownloadInvoice } from './helpers';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import {
  Data,
  InvoicesTableProps,
  Order,
  Row,
} from 'organisms/InvoicesTable/types';
import Link from 'atoms/Link/Link';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import Table from 'molecules/Table';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import Services from 'organisms/InvoicesTable/Services/Services';
import { InvoiceTimeLogModal } from 'molecules/TimeLogTable/InvoiceTimeLogModal';

export const InvoicesTable = ({
  search,
  status,
  currency,
  dateRange,
  onError,
  companyColumn = false,
  company,
  period,
  customer,
  setHeight,
}: InvoicesTableProps) => {
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const invoices = useBaoSelector((state) => state.invoices);
  const { value: user } = useBaoSelector((state) => state.user);
  const payInvoice = useBaoSelector((state) => state.payInvoice);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data[0]>('date');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [invoicesRows, setInvoicesRow] = useState<Row[]>([]);
  const [showServices, setShowServices] = useState(0);
  const [loader, setLoader] = useState(true);
  const [activeInvoice, setActiveInvoice] = useState<Row | null>(null);

  const isCustomer =
    user.userType !== SUPER_ADMIN &&
    user.userType !== ADMIN &&
    user.userType !== SALES_REP;

  const dispatch = useBaoDispatch();

  const searchParamsGet = new URLSearchParams(useLocation().search);
  const statusGet = searchParamsGet.get('status') as statusType;

  const columns = useMemo(
    () => [
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: companyColumn ? '14%' : '18%',
        render: ({ date }: Row) => (
          <StyledCellText
            $textType="bold"
            sx={{ fontSize: '0.813rem !important' }}
          >
            {date}
          </StyledCellText>
        ),
      },
      {
        key: 'invoiceQbNumber',
        label: 'Invoice #',
        sortable: false,
        width: companyColumn ? '14%' : '20%',
        render: ({ invoiceQbNumber }: Row) => (
          <StyledCellText>#{invoiceQbNumber}</StyledCellText>
        ),
      },
      ...(companyColumn
        ? [
            {
              key: 'company',
              label: 'Company',
              sortable: true,
              width: '25%',
              render: ({ company: companyName, companyHsId }: Row) => (
                <Link to={`/company/${companyHsId}`} $textDecoration="none">
                  <StyledCellText $textType="link">
                    {companyName}
                  </StyledCellText>
                </Link>
              ),
            },
          ]
        : []),
      {
        key: 'invoiceTotal',
        label: 'Invoice Total',
        sortable: true,
        width: companyColumn ? '15%' : '174px',
        render: ({ invoiceTotal, currency: rowCurrency }: Row) => (
          <StyledCellContainer
            $right
            $vStack
            $pr={companyColumn ? '20px' : '60px'}
          >
            <StyledCellText
              sx={companyColumn ? {} : { width: '114px', textAlign: 'end' }}
            >
              {invoiceTotal
                ? currencyFormatter(invoiceTotal, rowCurrency)
                : currencyFormatter(0, rowCurrency)}
            </StyledCellText>
          </StyledCellContainer>
        ),
      },
      {
        key: 'status',
        label: 'Status',
        greyText: 'Due date',
        sortable: false,
        width: companyColumn ? '15%' : '20%',
        render: ({
          status: invoiceStatus,
          dueDate,
          paidDate,
          amountDue,
          discountAmount,
        }: Row) => (
          <StyledCellContainer $vStack $display="inline">
            <InvoiceStatus>
              {invoiceStatus.state === 'open' &&
                invoiceStatus.days > 0 &&
                `OPEN DUE ${invoiceStatus.days} ${
                  invoiceStatus.days === 1 ? 'DAY' : 'DAYS'
                }`}

              {invoiceStatus.state === 'open' &&
                invoiceStatus.days === 0 &&
                'OPEN DUE TODAY'}

              {invoiceStatus.state === 'overdue' &&
                `OVERDUE ${invoiceStatus.days} ${
                  invoiceStatus.days === 1 ? 'DAY' : 'DAYS'
                }`}

              {invoiceStatus.state === 'paid' &&
                `PAID ${formatDate(paidDate, { time: '' })}${
                  amountDue === 0 && discountAmount ? ' by discount' : ''
                }`?.trim()}

              {invoiceStatus.state === 'payment_pending' && 'PENDING'}

              {invoiceStatus.state === 'pending' &&
                invoiceStatus.state.toUpperCase()}

              {invoiceStatus.state === 'voided' &&
                invoiceStatus.state.toUpperCase()}

              {invoiceStatus.state === 'past_due' &&
                invoiceStatus.state.toUpperCase()}
            </InvoiceStatus>

            <StyledCellText $textType="grey">{dueDate}</StyledCellText>
          </StyledCellContainer>
        ),
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        render: (row: Row) => (
          <ActionContainerStyled>
            {user.userType !== SUPER_ADMIN &&
              user.userType !== ADMIN &&
              user.userType !== SALES_REP &&
              row.status.state !== 'voided' &&
              row.status.state !== 'payment_pending' &&
              row.status.state !== 'paid' &&
              !companyColumn && (
                <PayButtonStyled
                  variant="contained"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setInvoice(row));
                  }}
                  color="secondary"
                >
                  Pay
                </PayButtonStyled>
              )}

            <IconButton
              sx={{ p: 0, '&:hover': { background: 'transparent' } }}
              onClick={(event) => {
                event.stopPropagation();
                handleDownloadInvoice([row.invoiceQbId]);
              }}
            >
              <PdfIcon height={24} width={24} />
            </IconButton>
          </ActionContainerStyled>
        ),
      },
    ],
    [companyColumn]
  );

  useEffect(() => {
    const payId = localStorage.getItem('pay');
    if (!payInvoice.open && payId) {
      const prevOpenInv = invoices.rows.find((inv) => inv.id === payId);
      if (prevOpenInv) {
        dispatch(setInvoice(prevOpenInv));
      }
    }
  }, [payInvoice, invoices]);

  useEffect(() => {
    if (isCustomer && !currentCompany) return;

    fetchPageData();
  }, [
    status,
    location,
    invoices.page,
    rowsPerPage,
    dispatch,
    searchParams,
    company,
    orderBy,
    order,
    search,
    currency,
    dateRange,
    period,
    customer,
    statusGet,
    currentCompany,
  ]);

  useEffect(() => {
    if (invoices.rows && invoices.rows.length > 0) {
      setLoader(false);
    }

    if (
      invoices.rows &&
      invoices.rows.length === 0 &&
      Number(searchParams.get('page')) > 0 &&
      invoices.totalCount > 0
    ) {
      // searchParams.set('page', '0');
      // navigate(`?${searchParams}`);
    }
    setInvoicesRow(invoices.rows);

    setTimeout(() => {
      if (invoices && invoices.totalCount === 0) {
        setLoader(false);
      }
    }, 5000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  useEffect(() => {
    setShowServices(0);
  }, [period, status]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data[0]
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setLoader(true);
    setTimeout(() => {
      // searchParams.set('page', newPage.toString());
      // navigate(`?${searchParams}`);
      dispatch(setPage(newPage));
    }, 500);
  };

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    // searchParams.set('page', '0');
    // navigate(`?${searchParams}`);
    dispatch(setPage(0));
  };

  const handleRowClick = useCallback(
    (event, row) => {
      if (window.getSelection()?.type === 'Range') return;

      if (showServices === row.id) {
        setShowServices(0);
        if (setHeight) {
          setHeight(false);
        }
      } else {
        setShowServices(row.id);
        if (setHeight) {
          setHeight(true);
        }
      }
    },
    [showServices]
  );

  const fetchPageData = () => {
    const newPage = Number(searchParams.get('page')) || invoices.page || 0;
    let isAll = false;
    if (
      statusGet &&
      statusGet === 'all' &&
      location.state &&
      location.state.type &&
      location.state.type === 'all'
    ) {
      isAll = true;
    }

    dispatch(
      storeInvoicesInfo({
        customer,
        period,
        newPage,
        rowsPerPage,
        status: isAll ? 'all' : status,
        sort: `sort[${snakeCase(orderBy)}]=${order}`,
        currency,
        company,
        search,
        dateRange,
      })
    );
  };

  const handleOpenLog = useCallback((invoice: Row) => {
    setActiveInvoice(invoice);
    document.body.setAttribute('id', 'body');
  }, []);

  const handleCloseLog = useCallback(() => {
    setActiveInvoice(null);
    document.body.setAttribute('id', '');
  }, []);

  const rowDetailsRenderer = useCallback(
    (row) => <Services row={row} onOpenLog={handleOpenLog} />,
    []
  );

  return (
    <>
      <StyledTableContainer>
        <Table
          idKey="id"
          loading={loader || invoices.isLoading}
          collapsible
          collapsedRow={showServices}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          data={invoicesRows || []}
          onRowClick={handleRowClick}
          rowDetailsRenderer={rowDetailsRenderer}
        />
        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={invoices.totalCount ? invoices.totalCount : 0}
          rowsPerPage={rowsPerPage}
          page={invoices.page}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100, 200]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>

      {activeInvoice && (
        <InvoiceTimeLogModal invoice={activeInvoice} onClose={handleCloseLog} />
      )}

      <PayInvoicePopup onAfterClose={fetchPageData} onError={onError} />
    </>
  );
};

export default InvoicesTable;
